import { useState } from "react";

import profile from "../assets/1635807032159-02.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faInstagram,
  faLinkedinIn,
  faTwitch,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import HrCurve from "./HrCurve";

export default function Hiro() {
  const [loaded, setLoaded] = useState(true);

  return (
    <>
      {/* {loaded ?
        <div
            className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white flex flex-col items-center justify-center"
        >Loading...</div>
            :
            null
        } */}
      <div
        id="home"
        className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative"
      >
        <div className="md:w-3/6 md:p-4">
          <img
            data-aos-duration="1500"
            style={{
              float: "right",
              borderRadius: "50%",
              border: "3px solid #2dd4bf",
            }}
            rounded
            data-aos-offset="200"
            width={"55%"}
            height={"55%"}
            src={profile}
            alt="profile"
            onLoad={() => setLoaded(true)}
          />
        </div>
        <div
          className="md:w-3/6"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-offset="100"
        >
          <div className="flex flex-col w-full mt-8">
            {/* <h1 className="text-xl text-gray-400">Hi, I'm</h1> */}
            <h1 className="text-3xl font-bold">Nikolaj Skovgaard Iversen</h1>
            <p class="text-xl font-bold text-gray-300">
              Frontend developer, professional gamer and coach, game developer
            </p>
            <p className="text-md font-light text-gray-400 ">
              Frontend developer with a passion for creating visually appealing
              user interfaces. I excel in translating design concepts into
              responsive web apps or mobile apps and creating user-friendly
              solutions.
              <br />
              <br />
              Professional gamer in various FPS games such as Quake and
              Diabotical with ~6000 USD in tournament winnings. More info on my
              Liquipedia page.
              <br />I have coached multiple people up to a competitive level in
              multiple games. My coaching includes everything from in-game
              mechanics to strategy and mentality.
              <br />
              <br />
              Passionate game developer and designer. Currently working on
              mobile games.
            </p>
          </div>
          {/* <a href='http://google.com' className='mt-2 block'>Gå til min blog <FontAwesomeIcon className='ml-2' icon={faCircleArrowRight}/> </a> */}
          <ul className="flex mt-2 gap-4 items-center">
            <li>
              <a
                href="https://www.linkedin.com/in/nikolaj-iversen-525279224/"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon
                  size="2xl"
                  icon={faLinkedinIn}
                  color="#2dd4bf"
                />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/gosuenesy"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon size="2xl" icon={faGithub} color="#2dd4bf" />
              </a>
            </li>
            <li>
              <a
                href="https://twitch.tv/enesy_"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon size="2xl" icon={faTwitch} color="#2dd4bf" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/gosuenesy"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon size="2xl" icon={faTwitter} color="#2dd4bf" />
              </a>
            </li>
          </ul>
        </div>
        {/* <img src={hr} className="w-full md:h-2 absolute bottom-0" alt="hr" /> */}
      </div>
    </>
  );
}
