import hr from "../assets/curve-hr.svg";
export default function HrCurve() {
  return (
    // <img src={hr} className="w-full md:h-3 mt-8" alt="hr" />
    <hr
      style={{
        border: "0px",
        width: "20%",
        height: "3px",
        marginTop: "32px",
        marginBottom: "32px",
        marginLeft: "auto",
        marginRight: "auto",
        background: "#0d9488",
      }}
    />
  );
}
