import hr from "../assets/curve-hr.svg";
import HrCurve from "./HrCurve";

export default function Footer() {
  return (
    <div className="mt-4 bg-dark-200 rounded-md text-white px-8 py-4">
      <ul className="text-center">
        <li>
          <a href="#home" className="hover:text-teal-400">
            Profil
          </a>
        </li>
        <li>
          <a href="#skills" className="hover:text-teal-400">
            Kompetencer
          </a>
        </li>
        {/* <li><a href="#honors" className="hover:underline">Honor & Awards</a></li> */}
        <li>
          <a href="#certs" className="hover:text-teal-400">
            Projekter
          </a>
        </li>
        <li>
          <a href="#livestream" className="hover:text-teal-400">
            Livestream
          </a>
        </li>
        <li>
          <a href="#coaching" className="hover:text-teal-400">
            Coaching
          </a>
        </li>
      </ul>
      <HrCurve />
      {/* <img src={hr} className="mb-4 mt-4 w-full md:h-1 text-white bottom-0" alt="hr" /> */}
      <p className="text-sm font-light text-center">
        Copyright © 2024 Nikolaj Iversen
      </p>
    </div>
  );
}
